<template>
  <TsCard
    id="trolley-preview"
    :data-testid="props.dataTestid + '-container'"
    :append-class="
      twMerge(
        'max-h-[inherit] flex flex-col overflow-auto px-3 py-4 bg-white',
        isMobile && 'rounded-b-none'
      )
    "
  >
    <div class="flex items-center justify-between mb-2.5">
      <TsRow append-class="m-0 pr-7">
        <TsTypography
          v-if="trolleyStore.previewed_product?.full_name"
          append-class="mb-0 font-medium text-idle-black"
          :data-testid="props.dataTestid + '-product-full-name'"
          as="span"
        >
          <strong>{{
            trolleyStore.previewed_product?.full_name.split(" ")[0]
          }}</strong>
          {{
            trolleyStore.previewed_product?.full_name
              ?.split(" ")
              .slice(1)
              .join(" ")
          }}
        </TsTypography>
        <TsTypography
          v-else-if="trolleyStore.previewed_product?.name"
          as="span"
          append-class="mb-0 font-bold text-idle-black"
          :data-testid="props.dataTestid + '-product-name'"
        >
          {{ trolleyStore.previewed_product?.name }}
        </TsTypography>
        <TsTypography
          v-else
          append-class="mb-0 text-idle-black"
          :data-testid="props.dataTestid + '-product-with-no-name'"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing
        </TsTypography>
      </TsRow>
      <TsButton
        class="hover:bg-info/10 rounded max-w-max"
        icon="mdi:close"
        size="lg"
        :data-testid="props.dataTestid + '-close-button'"
        @click="emit('close')"
      >
      </TsButton>
    </div>

    <!-- <template>
      <TsTypography as="h4"> Choose a Colour </TsTypography>

      <div class="flex items-center gap-2">
        <div>
          <div class="p-2 mb-2 border-2 border-primary rounded-xl">
            <NuxtImg src="/images/shoes-1.png" width="48" height="48" />
          </div>

          <TsTypography size="xs" align="center" append-class="text-primary">
            Black
          </TsTypography>
        </div>
        <div>
          <div class="p-2 mb-2 border border-natural-light-grey rounded-xl">
            <NuxtImg src="/images/shoes-1.png" width="48" height="48" />
          </div>

          <TsTypography
            size="xs"
            align="center"
            append-class="text-natural-black"
          >
            Brown
          </TsTypography>
        </div>
        <div>
          <div
            class="overflow-hidden p-2 mb-2 border border-natural-light-grey rounded-xl relative after:content-[''] after:w-28 after:rotate-45 after:border after:border-natural-light-grey after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2"
          >
            <NuxtImg
              class="opacity-40"
              src="/images/shoes-1.png"
              width="48"
              height="48"
            />
          </div>

          <TsTypography
            size="xs"
            align="center"
            append-class="text-natural-black/50"
          >
            Honey
          </TsTypography>
        </div>
      </div>

      <TsDivider />

      <TsTypography variant="h2" append-class="mb-0 leading-none"
        >Size: 40</TsTypography
      >

      <TsTypography append-class="text-natural-black mb-4" weight="medium">
        Garment Measurement: <span class="font-normal">Chest 40.0in</span>
      </TsTypography>

      <div class="flex items-center justify-between mb-4">
        <TsTag
          data-testid=""
          variant="info"
          label="Size 40 recommended"
          append-class="outline-0 border-0 py-[3px]"
          size="sm"
          outlined
        />

        <TsButton
          label="Size chart"
          icon="uil:angle-right"
          icon-position="right"
          icon-class="size-6"
          append-class="border-0 p-0 gap-0"
          outlined
        />
      </div>

      <div class="flex items-center gap-2 mb-2">
        <div
          class="h-12 w-12 relative border border-natural-light-grey rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border-2 border-[#094983] bg-primary text-idle-white rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border border-natural-light-grey rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border overflow-hidden border-natural-light-grey bg-natural-soft-white text-natural-light-grey rounded-xl after:content-[''] after:w-16 after:rotate-45 after:border after:border-natural-light-grey after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>
      </div>

      <TsTypography append-class="text-[#E52228] mb-4" size="xs">
        10 left
      </TsTypography>
    </template> -->

    <template v-if="trolleyStore.previewed_product?.variations?.length">
      <TsTypography
        as="h4"
        :data-testid="props.dataTestid + '-select-variant-text'"
      >
      {{useTranslation("productVariant" , "Selecteer Productvarianten")}}({{
          trolleyStore.previewed_product?.variations?.length
        }})
      </TsTypography>

      <TsButton
        :label="selectedVariantLabel"
        variant="secondary"
        icon="pepicons-pop:angle-down"
        iconPosition="right"
        :data-testid="props.dataTestid + '-choose-variant-button'"
        append-class="mb-4 bg-white justify-between"
        iconClass="text-natural-silver-grey"
        @click="emit('clickVariants')"
        outlined
      />
    </template>

    <template v-if="props.loading">
      <TsBranchTransition
        v-for="n in 2"
        :key="n"
        :data-testid="props.dataTestid + '-initial-loader'"
      />
    </template>

    <template v-else>
      <TsMedia>
        <TsMediaStart append-class="p-4">
          <TsQuantityCounter
            v-model="trolleyStore.previewed_product_selected_quantity"
            :data-testid="props.dataTestid + '-quantity-control'"
          />

          <TsTag
            v-if="selectedChannelStockTag"
            :label="selectedChannelStockTag"
            :data-testid="props.dataTestid + '-selected-channel-stock'"
            variant="info"
            rounded
            size="sm"
            append-class="mt-3 px-6"
          />
        </TsMediaStart>

        <TsMediaContent
          v-if="
            trolleyStore.previewed_product &&
            trolleyStore.previewed_product.prices
          "
          append-class="pl-4"
        >
          <div class="flex">
            <div :class="`trolleyStore.previewed_product.prices?.raw.gross < trolleyStore.previewed_product.prices.was_price_raw && h-4`">
            <TsTypography
              size="sm"
              v-if="(trolleyStore.previewed_product?.prices && trolleyStore.previewed_product?.prices?.raw && trolleyStore.previewed_product?.prices?.was_price_formatted)
                  && trolleyStore.previewed_product?.prices?.raw.gross < trolleyStore.previewed_product?.prices.was_price_raw"
              :data-testid="props.dataTestid + '-recommended-retail-price-old'"
              append-class="line-through leading-tight mr-1"
            >
              {{
                `${trolleyStore.previewed_product.prices.was_price_formatted}`
              }}
            </TsTypography>
            </div>

            <TsTypography
              v-if="
                trolleyStore.previewed_product.prices.formatted
                  .discount_percentage !== '0%'
              "
              size="sm"
              weight="bold"
              :data-testid="props.dataTestid + '-discount-percentage'"
              append-class="text-success leading-tight"
            >
              ({{
                trolleyStore.previewed_product?.prices?.formatted
                  .discount_percentage
              }}
              OFF)
            </TsTypography>
          </div>

          <TsTypography
            variant="h2"
            weight="bold"
            append-class="text-idle-black leading-normal mb-0 leading-tight"
            :data-testid="
              props.dataTestid +
              (rootStore.isIncVat
                ? `-formatted-gross-price`
                : `-formatted-net-price`)
            "
          >
            {{
              rootStore.isIncVat
                ? trolleyStore.previewed_product.prices.formatted.gross
                : trolleyStore.previewed_product.prices.formatted.net
            }}
            <TsTypography
              v-if="trolleyStore.previewed_product.prices.formatted.per_unit"
              as="span"
              variant="xs"
              append-class="text-natural-silver-grey leading-tight"
              :data-testid="props.dataTestid + `-formatted-per-unit-price`"
            >
              {{ trolleyStore.previewed_product.prices.formatted.per_unit }}
            </TsTypography>
          </TsTypography>

          <TsTypography as="span" variant="xs" append-class="leading-tight">
            <span :data-testid="props.dataTestid + '-vat-indicator-text'">
              {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
            </span>
            <span
              :data-testid="
                props.dataTestid +
                (rootStore.isIncVat
                  ? `-formatted-net-price`
                  : `-formatted-gross-price`)
              "
            >
              {{
                rootStore.isIncVat
                  ? trolleyStore.previewed_product.prices.formatted.net
                  : trolleyStore.previewed_product.prices.formatted.gross
              }}
            </span>
          </TsTypography>
        </TsMediaContent>
      </TsMedia>

      <TsDivider />

      <TsTypography as="h4"> {{useTranslation('howToGetDelivered' , 'Hoe u het kunt laten bezorgen')}} </TsTypography>

      <div class="md:flex md:gap-2">
        <!-- Add to trolley delivery channel -->
        <TsRadioTrolleyDelivery
          v-model="trolleyStore.previewed_product_selected_channel"
          :value="TrolleyChannel.Delivery"
          :disabled="isOutOfStockForDelivery"
          :is-out-of-stock="isOutOfStockForDelivery"
          :delivery-stock="deliveryStockTag"
          name="channel_option"
          :data-testid="props.dataTestid + '-delivery-option'"
        />

        <!-- Add to trolley collection channel -->
        <TsRadioTrolleyCollection
          v-if="
            trolleyStore.previewed_product?.click_and_collect &&
            !trolleyStore.previewed_product.direct_ship
          "
          v-model="trolleyStore.previewed_product_selected_channel"
          :value="TrolleyChannel.Collection"
          :availability="collectionAvailability"
          :branch-name="branchStore.lastSavedBranch?.name"
          @set-branch="emit('setBranch')"
          :loading="trolleyStore.collection_stock_loading"
          name="channel_option"
          :data-testid="props.dataTestid + '-collection-option'"
          :disabled="!branchStore.is_branch_set || !collectionStockTag"
        />
      </div>

      <slot name="cta">
        <template v-if="isOutOfStockForCollection && isOutOfStockForDelivery">
          <TsButton
            v-if="isSubscribedToStock"
            icon="material-symbols:unsubscribe-outline"
            :label="unsubscribeLabel"
            @click="handleStockUnsubscribe(trolleyStore.previewed_product!.code)"
            :append-class="ctaBaseClass"
            :data-testid="props.dataTestid + '-stock-unsubscribe-cta'"
            size="sm"
            :is-loading="isStockUnsubscribeCTAloading"
            outlined
            block
          />
          <TsButton
            v-else
            icon="mdi:bell-outline"
            :label="notifyMeLabel"
            :append-class="ctaBaseClass"
            size="sm"
            @click="emit('notify', trolleyStore.previewed_product!.code, trolleyStore.previewed_product!.full_name || trolleyStore.previewed_product!.name)"
            :data-testid="props.dataTestid + '-stock-notify-cta'"
            block
          />
        </template>

        <template v-else>
          <TsButton
            v-if="mode === 'checkout'"
            icon="fa:angle-double-right"
            label="Buy now"
            :data-testid="props.dataTestid + '-buy-now-cta'"
            :append-class="ctaBaseClass"
            size="sm"
            block
            @click="
              emit(
                'buyNow',
                trolleyStore.previewed_product_selected_quantity,
                trolleyStore.previewed_product_selected_channel
              )
            "
          />

          <TsButton
            v-if="mode === 'trolley'"
            icon="bx:cart"
            :label="
              trolleyStore.is_added ? 'Added to trolley' : 'Add to trolley'
            "
            :is-loading="props.addToTrolleyCtaLoading"
            :append-class="
              twJoin(
                ctaBaseClass,
                trolleyStore.is_added &&
                  'bg-success hover:bg-success-hover border-success text-idle-white'
              )
            "
            size="sm"
            block
            :data-testid="props.dataTestid + '-add-to-trolley-cta'"
            @click="
              emit(
                'addToTrolley',
                trolleyStore.previewed_product_selected_quantity
              )
            "
            :disabled="isAddToTrolleyButtonDisabled"
          />
        </template>

      </slot>
    </template>
  </TsCard>
</template>

<script setup lang="ts">
const rootStore = useRootStore();
import { twJoin, twMerge } from "tailwind-merge";
import type { ProductAvailability } from "../atoms/TsRadioTrolleyCollection.vue";
import TsTypography from "../atoms/TsTypography.vue";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
import { useTranslation } from "~/composables/useTranslation";

type Props = {
  dataTestid?: string;
  mode?: "trolley" | "checkout";
  loading?: boolean;
  addToTrolleyCtaLoading?: boolean;
  selectedVariantLabel?: string;
  collectionAvailability?: ProductAvailability;
};

const props = withDefaults(defineProps<Props>(), {
  mode: "trolley",
  collectionAvailability: () => ({ status: "NoBranchSelected", stock: 0 }),
  selectedVariantLabel: "3x61 mm",
});

const emit = defineEmits<{
  addToTrolley: [q: number];
  buyNow: [q: number, c: number];
  notify: [code: string, name: string];
  setBranch: any;
  close: any;
  clickVariants: any;
}>();

const { isMobile } = useDevice();

const ctaBaseClass = "mt-4 gap-1.5 mx-auto max-w-sm";

const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();

/* OOS notification */
const isStockUnsubscribeCTAloading = ref(false);

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

const isSubscribedToStock = computed(() => {
  const authStore = useAuthStore();
  if (!authStore.user || !(authStore.user as any).id) return false;

  const accountStore = useAccountStore();

  return accountStore.stock_notifications.some((notification: any) => {
    return notification.product_id === trolleyStore.previewed_product?.code;
  });
});

function recalculateCollectionStock(chosen_quantity: number) {
  const product = trolleyStore.previewed_product;

  if (!product) return;

  const { stockDetails } = product;

  if (!stockDetails) return;

  const { collection, delivery } = stockDetails;

  if (!branchStore.is_branch_set) return;
  // enable next day collection
  if (chosen_quantity > collection! && chosen_quantity <= delivery!) {
    trolleyStore.collection_availability = {
      status: "NextDayCollection",
      stock: delivery!,
    };
  }
  // enable oos
  else if (chosen_quantity > collection! && chosen_quantity > delivery!) {
    trolleyStore.collection_availability = {
      status: "OutOfStock",
      stock: 0,
    };
  }
  // go back to collection if neither is true
  else {
    trolleyStore.collection_availability = {
      status: "Collection",
      stock: collection!,
    };
  }
}

const isOutOfStockForDelivery = computed(() => {
  const product = trolleyStore.previewed_product;
  const selectedQuantity = trolleyStore.previewed_product_selected_quantity;
  if (!product || !product.stockDetails?.delivery) return true;
  return selectedQuantity > product.stockDetails.delivery;
});

const isOutOfStockForCollection = computed(
  () => props.collectionAvailability.status === "OutOfStock"
);

const deliveryStockTag = computed<string>(
  () =>
    (trolleyStore.previewed_product?.stockDetails?.delivery! <= 20
      ? trolleyStore.previewed_product?.stockDetails?.delivery!.toString()
      : "20+") + " available"
);

const collectionStockTag = computed<string>(() => {
  if (!props.collectionAvailability.stock) return "";
  return (
    (props.collectionAvailability.stock <= 20
      ? props.collectionAvailability.stock
      : "20+") + " available"
  );
});

const selectedChannelStockTag = computed<string>(() =>
  trolleyStore.previewed_product_selected_channel === TrolleyChannel.Delivery
    ? deliveryStockTag.value
    : collectionStockTag.value
);

const isAddToTrolleyButtonDisabled = computed(() => {
  const selectedQuantity = trolleyStore.previewed_product_selected_quantity;
  const selectedChannel = trolleyStore.previewed_product_selected_channel;
  const product = trolleyStore.previewed_product;
  const stockDetails = product?.stockDetails;

  const isGreaterThanDeliveryStock = selectedQuantity > stockDetails?.delivery!;
  const isGreaterThanCollectionStock =
    selectedQuantity > stockDetails?.collection!;

  const isEvaluatingCollectionStock = trolleyStore.collection_stock_loading;

  return (
    trolleyStore.is_added ||
    !selectedChannelStockTag ||
    !selectedQuantity ||
    (selectedChannel === TrolleyChannel.Delivery
      ? isGreaterThanDeliveryStock
      : props.collectionAvailability.status === "NextDayCollection"
      ? isGreaterThanDeliveryStock
      : isGreaterThanCollectionStock) ||
    (selectedChannel === TrolleyChannel.Collection &&
      isEvaluatingCollectionStock)
  );
});

// reset the buttons if either the quantity or the channel changes
const unwatch = watch(
  [
    () => trolleyStore.previewed_product_selected_channel,
    () => trolleyStore.previewed_product_selected_quantity,
  ],
  ([new_channel, new_quantity]) => {
    trolleyStore.is_added = false;
    if (new_quantity && new_quantity > 0)
      recalculateCollectionStock(new_quantity);
  }
);

const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");
const unsubscribeLabel = useTranslation("unsubscribeFromStock", "Afmelden voor voorraadmelding");
</script>
